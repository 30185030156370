import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import imageOne from "../dist/imgs/Prato1-1600-1200.jpg";
import imageTwo from "../dist/imgs/Prato2-1600-1200.jpg";
import imageThree from "../dist/imgs/Prato3-1600-1200.jpg";
import imageFour from "../dist/imgs/Prato4-1600-1200.jpg";
import imageFive from "../dist/imgs/Prato5-1600-1200.jpg";

export default function Slick() {
  const sliderList = [imageOne, imageTwo, imageThree, imageFour, imageFive];
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoSpeed: 2000,
    arrows: false
  };
  return (
    <div className="sliderWrapper">
      <Slider {...settings}>
        {sliderList.map((value, key) => {
          return (
            <div key={key}>
              <img src={value} alt="Pratos Restaurante A Bruxa"></img>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
