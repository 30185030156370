// React and Libraries imports
import React, { Component } from "react";
import detectBrowserLanguage from "detect-browser-language";

// Components/Section imports
import Cookies from "./components/Cookies";
import Navbar from "./components/Navbar";
import Home from "./sections/home";
import Historia from "./sections/historia";
import Ementas from "./sections/ementas";
import Localizacao from "./sections/localizacao";
import Contactos from "./sections/contactos";
import Footer from "./components/Footer";

// Styling imports
import "./App.css";
import "./Main.css";

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      lang: "pt-PT",
      width: window.innerWidth
    };
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.setState({ lang: detectBrowserLanguage() });
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 700;

    return (
      <div className="App container">
        <Cookies lang={this.state.lang}></Cookies>
        <Navbar lang={this.state.lang} isMobile={isMobile} />
        <Home />
        <Historia lang={this.state.lang} />
        <Ementas isMobile={isMobile} lang={this.state.lang} />
        <Localizacao lang={this.state.lang} />
        <Contactos lang={this.state.lang} />
        <Footer />
      </div>
    );
  }
}
