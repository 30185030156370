import React from "react";
import theFork from "../dist/icons/thefork.svg";
import tripAdvisor from "../dist/icons/tripadvisor.svg";
import ficha from "../dist/ficha_projeto_site.pdf";
import cofinanciado from "../dist/imgs/cofinanciado.jpg";

const Footer = () => {
  const socialMediaList = [
    {
      path:
        "https://www.tripadvisor.pt/Restaurant_Review-g189106-d19493233-Reviews-Restaurante_A_Bruxa_d_Evora-Evora_Evora_District_Alentejo.html",
      icon: tripAdvisor,
    },
    {
      path: "https://www.thefork.pt/restaurante/a-bruxa-d-evora-r566285/",
      icon: theFork,
    },
  ];

  return (
    <footer>
      {socialMediaList.map((value, index) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={value.path}
            key={index}
          >
            <img srcSet={value.icon} alt="icon" style={{ margin: "0 .5rem" }} />
          </a>
        );
      })}
      <a className="cofinanc" href={ficha}>
        <img src={cofinanciado} alt="Projecto Cofinanciado por: "></img>
      </a>
      <p style={{ textAlign: "center" }}>
        MADE WITH <i className="fas fa-heart"></i> BY TIAGO ALEXANDRINO -
        CREATIVE AGENCY 2019®
      </p>
    </footer>
  );
};

export default Footer;
