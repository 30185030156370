import imageOne from "./dist/imgs/figura1-900.png";
import imageTwo from "./dist/imgs/figura2-900.png";
import imageThree from "./dist/imgs/figura3-900.png";

const jsonTranslations = {
  pt: {
    navbar: [
      { text: "História", path: "#about", y: 1000 },
      { text: "Ementas", path: "#services", y: 1800 },
      { text: "Localização", path: "#localization", y: 2500 },
      { text: "Contactos", path: "#contacts", y: 3300 }
    ],
    historia: [
      {
        src: imageOne,
        alt: "figure1",
        text:
          "A Feiticeira ou Bruxa de Évora, de seu nome Lagarrona, é uma das personagens mais populares e misteriosas do folclore e das lendas de magia. A Bruxa de Évora foi tão poderosa e influente que o seu nome se tornou referência para praticantes de bruxaria que vieram muito depois e alcançou, não somente a Península Ibérica, mas também toda a região dos Balcãs, as ilhas do mar Mediterrâneo e o Oriente Médio."
      },
      {
        src: imageTwo,
        alt: "figure2",
        text:
          "As pesquisas sobre esta feiticeira indicam que boa parte da sua fama nasce junto com a fama de um outro mago controverso: Cipriano de Antioquia. Este, depois de uma vida dedicada à magia negra, converteu-se ao Cristianismo e foi até canonizado, passado a fazer parte da História Cristã como São Cipriano. Segundo as lendas, o nome “Bruxa de Évora”, ou Bruxa de Yeborath, aparece pela primeira vez ligado ao nome do Santo Feiticeiro. Ela teria sido uma das mestras do mago e ele o seu discípulo mais prestigiado, herdeiro dos seus feitiços."
      },
      {
        src: imageThree,
        alt: "figure3",
        text:
          "Cipriano, o Feiticeiro, não somente encontrou a Bruxa de Évora na sua passagem pela Babilónia, como dela teria herdado os livros, as poções e os segredos dos poderes da sua Mestra. Ao contrário do modelo da curandeira intuitiva, analfabeta, camponesa ignorante cujo conhecimento é todo proveniente da experiência pessoal e herança de tradições orais, a Bruxa de Évora é praticamente uma erudita Alfabetizada, poliglota, ela fala fluentemente, lê e escreve em Português (o gentílico da época) árabe e latim. De SãoCipriano ficaram-nos vários livros famosos, incluindo o seu livro de receitas culinárias. Infelizmente, na nossa carta, não consta nenhuma das receitas do Santo."
      }
    ]
  },
  en: {
    navbar: [
      { text: "History", path: "#about", y: 1000 },
      { text: "Menus", path: "#services", y: 1800 },
      { text: "Localization", path: "#localization", y: 2500 },
      { text: "Contacts", path: "#contacts", y: 3300 }
    ],
    history: [
      {
        src: imageOne,
        alt: "figure1",
        text:
          "The Witch of Évora, the sorceress Lagarrona, is one of the best-known and most mysterious figures in popular culture and magical legends, and has had a powerful influence on practitioners of witchcraft over the ages - her prestige extended well beyond the Iberian Peninsula, to the Balkans, the Mediterranean islands and the Middle East."
      },
      {
        src: imageTwo,
        alt: "figure2",
        text:
          "Research shows that Lagarrona’s renown is bound up with that of the controversial wizard. Cyprian of Antioch, a long-standing devotee of the black arts, who was accepted into the Roman Church and eventually canonized as the Christian Saint Cyprian. In the first reference to the witch of Évora (or Yeborath), in a legend most likely dating from the early Middle Ages, Lagarrona is associated with the wizard saint, who probably received instruction from her in witchcraft during his time in Babylon; her most devoted disciple and heir to her magical prowess. Cyprian inherited her books, potions, and the secrets of her powers."
      },
      {
        src: imageThree,
        alt: "figure3",
        text:
          "The sorceress herself may be regarded as something of a scholar; no charlatan, or illiterate, ignorant peasant whose knowledge was based on intuition, personal experience or oral tradition, she was widely read, and spoke several languages fluently - reading and writing in Portuguese, Arabic and Latin. For this part, Cyprian bequeathed the posterity a number of well-know works, including a book of culinary recipes. Unfortunately, our menu does not feature dished based on the saint’s formulas!"
      }
    ]
  }
};

export default jsonTranslations;
