import React from "react";
import PropTypes from "prop-types";
// import { slide as Menu } from "react-burger-menu";
import { ScrollTo } from "react-scroll-to";

import logo from "../dist/logo.svg";
import jsonTranslations from "../translations";

const Navbar = ({ lang }) => {
  let navbarList = [];

  lang === "pt-PT" || lang === "pt"
    ? (navbarList = jsonTranslations.pt.navbar)
    : (navbarList = jsonTranslations.en.navbar);

  return (
    <nav className="navbar bg-dark container">
      <ScrollTo>
        {({ scroll }) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            style={{ padding: "0" }}
            onClick={() => scroll({ y: 0, smooth: true })}
          >
            <img className="logo" srcSet={logo} alt="A Bruxa" />
          </a>
        )}
      </ScrollTo>
      <ul>
        {navbarList.map((value, key) => {
          return (
            <li key={key}>
              <ScrollTo>
                {({ scroll }) => (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    onClick={() =>
                      scroll({
                        y: value.y,
                        smooth: true
                      })
                    }
                  >
                    {value.text}
                  </a>
                )}
              </ScrollTo>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

Navbar.propTypes = {
  lang: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default Navbar;
