import React from "react";
import Slick from "../components/Slick";

import logoWhite from "../dist/logo_white.svg";
import pdfENG from "../dist/menus/ementa_ENG.pdf";
import pdfPT from "../dist/menus/ementa_PT.pdf";

const Ementas = ({ isMobile, lang }) => {
  // let carouselList = [imageOne, imageTwo, imageThree, imageFour, imageFive];
  let title,
    subtitle = "";
  var pdf;

  if (lang === "pt-PT") {
    title = "Os nossos Pratos";
    subtitle = "Ver ementa completa";
    pdf = pdfPT;
  } else {
    title = "Our Plates";
    subtitle = "View full menu";
    pdf = pdfENG;
  }

  if (isMobile) {
    return (
      <section
        className="section-services"
        id="services"
        style={{ backgroundColor: "#1a1919" }}
      >
        <div
          className="intro"
          style={{
            backgroundColor: "#1a1919",
            color: "white",
            padding: "1.5rem 0"
          }}
        >
          <h1>{title}</h1>
          <img
            srcSet={logoWhite}
            alt="LogoBlack"
            style={{ width: "3rem", margin: "10px" }}
          />
        </div>
        <Slick />
        <div
          className="details"
          style={{ backgroundColor: "#1a1919", textAlign: "center" }}
        >
          <a className="btn btn-ghost" href={pdf} style={{ lineHeight: "2.2" }}>
            {subtitle}
          </a>
        </div>
      </section>
    );
  } else {
    return (
      <section className="section-services" id="services">
        <div style={{ display: "flex", flexDirection: "row", height: "712px" }}>
          <Slick />
          <div style={{ width: "50%" }}>
            <div
              className="intro"
              style={{
                backgroundColor: "#1a1919",
                color: "white",
                height: "720px"
              }}
            >
              <h1>{title}</h1>
              <img
                srcSet={logoWhite}
                alt="LogoBlack"
                style={{ width: "3rem", margin: "10px" }}
              />
              <a
                className="btn btn-ghost"
                href={pdf}
                style={{ lineHeight: "2.2" }}
              >
                {subtitle}
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Ementas;
