import React from "react";

import logoBlack from "../dist/logo_black.svg";

const localizacao = ({ lang }) => {
  let title,
    subtitle = "";
  if (lang === "pt-PT") {
    title = "Localização";
    subtitle = "Estamos localizados na seguinte morada:";
  } else {
    title = "Localization";
    subtitle = "Meet us in the following address:";
  }

  return (
    <section className="section-localization" id="localization">
      <div className="intro">
        <h1>{title}</h1>
        <img
          srcSet={logoBlack}
          alt="figure"
          style={{ width: "3rem", margin: "10px" }}
        />
        <p>{subtitle}</p>
        <hr
          style={{ width: "150px", height: "3px", backgroundColor: "#1a1919" }}
        />
        <p style={{ textAlign: "center" }}>
          Rua 5 de Outubro, 86 <br />
          7000-854 Évora
        </p>
      </div>
      <iframe
        title="mapaRestaurante"
        src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d194.96016243169902!2d-7.907746702548731!3d38.5714970529535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0xd19e533eca44521%3A0xded0794a92a42ee0!2sA%20Bruxa%20D&#39;%C3%89vora%2C%20R.%20Cinco%20de%20Outubro%2086%2C%207000-854%20%C3%89vora%2C%20Portugal!3m2!1d38.5714761!2d-7.907670899999999!5e0!3m2!1sen!2sus!4v1571592277477!5m2!1sen!2sus"
        samesite="strict"
        frameBorder="0"
        allowFullScreen={false}
      ></iframe>
    </section>
  );
};

export default localizacao;
