import React, { Component } from "react";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import jsonTranslations from "../translations";
import logoBlack from "../dist/logo_black.svg";

export default class Historia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange = e => this.setState({ value: e.target ? e.target.value : e });

  render() {
    let carouselList = [];
    let title = "";

    if (this.props.lang === "pt-PT") {
      carouselList = jsonTranslations.pt.historia;
      title = "A História";
    } else {
      carouselList = jsonTranslations.en.history;
      title = "History";
    }

    return (
      <section className="section-about" id="about">
        <div className="container-content">
          <div className="intro">
            <h1>{title}</h1>
            <img
              srcSet={logoBlack}
              alt="LogoBlack"
              style={{ width: "3rem", margin: "10px" }}
            />
          </div>
          <Carousel
            arrowLeft={<i className="fas fa-chevron-left"></i>}
            arrowRight={<i className="fas fa-chevron-right"></i>}
            addArrowClickHandler
            centered
            value={this.state.value}
            onChange={this.onChange}
            infinite
            draggable
          >
            {carouselList.map((value, key) => {
              return (
                <div
                  className="history-slide"
                  key={key}
                  style={{ textAlign: "center" }}
                >
                  <img src={value.src} alt={value.alt} />
                  <p style={{ textAlign: "justify" }}>{value.text}</p>
                </div>
              );
            })}
          </Carousel>
          <Dots
            value={this.state.value}
            onChange={this.onChange}
            number={carouselList.length}
          />
        </div>
      </section>
    );
  }
}
