import React from "react";
import CookieConsent from "react-cookie-consent";

const Cookies = ({ lang }) => {
  if (lang === "pt" || lang === "pt-PT") {
    return (
      <CookieConsent
        location="bottom"
        buttonText="Eu Aceito"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#1A1919" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Este website utiliza cookies para melhorar a sua experiência de
        utilizador{" "}
      </CookieConsent>
    );
  } else {
    return (
      <CookieConsent
        location="bottom"
        buttonText="I accept"
        cookieName="myAwesomeCookieName2"
        samesite="strict"
        style={{ background: "#1A1919" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    );
  }
};

export default Cookies;
