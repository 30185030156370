import React, { Component } from "react";
import axios from "axios";

class Contactos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
      mailSent: false,
      error: null,
      show: false
    };
  }

  handleOnSubmit = e => {
    const urlPhp = "./api/email.php";
    if (
      (this.state.name !== null || this.state.name !== "") &&
      (this.state.email !== null || this.state.email !== "") &&
      (this.state.subject !== null || this.state.subject !== "") &&
      (this.state.message !== null || this.state.message !== "")
    ) {
      axios({
        method: "post",
        url: urlPhp,
        headers: { "content-type": "application/json" },
        data: this.state
      })
        .then(result => {
          e.preventDefault();
          this.setState({
            mailSent: result.data.sent
          });
        })
        .catch(error => this.setState({ error: error.message }));
    }
  };

  handleOnChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    let title,
      subtitle,
      name,
      phone,
      subject,
      msg,
      send,
      validationMsg = "";
    if (this.props.lang === "pt-PT") {
      title = "Contacto";
      subtitle =
        "Pode contactar-nos preenchendo o formulário abaixo ou através das redes sociais.";
      name = "Nome";
      phone = "Telefone";
      subject = "Assunto";
      msg = "Mensagem";
      send = "Enviar";
      validationMsg = "O seu email foi enviado com sucesso!";
    } else {
      title = "Contacts";
      subtitle =
        "You can contact us by filling the form below or in our social media.";
      name = "Name";
      phone = "Phone";
      subject = "Subject";
      msg = "Message";
      send = "Send";
      validationMsg = "Your email has been sent sucessfully!";
    }
    return (
      <section className="section-contacts" id="contacts">
        <div className="container-content">
          <div className="header">
            <h1>{title}</h1>
            <p style={{ textAlign: "center", padding: "10px" }}>{subtitle}</p>
          </div>
          <form>
            <div className="row">
              <div className="input-group">
                <label htmlFor="name">{name}*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  onChange={this.handleOnChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="phone">{phone}</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  onChange={this.handleOnChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="input-group">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  onChange={this.handleOnChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="subject">{subject}*</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  required
                  onChange={this.handleOnChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="input-group">
                <label htmlFor="message">{msg}*</label>
                <textarea
                  id="message"
                  name="message"
                  required
                  onChange={this.handleOnChange}
                />
              </div>
            </div>
            <div className="row row-submit">
              {!this.state.mailSent ? (
                <button
                  type="submit"
                  className="btn btn-ghost submit"
                  onClick={e => this.handleOnSubmit(e)}
                >
                  {send}
                </button>
              ) : (
                <button disabled className="btn btn-ghost submit">
                  {send}
                </button>
              )}
              {this.state.mailSent && <p>{validationMsg}</p>}
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default Contactos;
