import React from "react";
import heroImage from "../dist/imgs/Espaco3-1600.png";
import { ScrollTo } from "react-scroll-to";

function Hero() {
  return (
    <section className="section-home container" id="home">
      <img src={heroImage} alt="heroImage" />
      <div>
        <ScrollTo>
          {({ scroll }) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              onClick={() => scroll({ y: 1000, smooth: true })}
              className="btn arrowDown"
            >
              <i className="fas fa-chevron-down"></i>
            </a>
          )}
        </ScrollTo>
      </div>
    </section>
  );
}

export default Hero;
